import type {
	TSimulationQuestions,
	TSimulationSlice,
} from "../types/simulation.type";

export const extractSimulations = (
	data: any,
	defaultRunId: string
) => {
	if (data) {
		const res = Object.entries(data).map(
			([key, value]: Array<any>) => {
				if (key !== defaultRunId)
					return {
						id: key,
						...value,
					};
				else {
					return {
						id: key,
						...value,
						run_name: "Alloan Default Run",
					};
				}
			}
		);
		return res;
	} else {
		return [];
	}
};

export const extractSimulationAxisData = (
	data: Array<any>
) => {
	const result: any = {
		price: [],
	};
	data.forEach((element) => {
		if (
			element.assumptions &&
			typeof element.assumptions === "object"
		) {
			Object.entries(element.assumptions).forEach(
				([key, value]) => {
					if (result[key]) {
						result[key].push(value);
					} else {
						result[key] = [value];
					}
				}
			);
		}
		result.price.push(element.price);
	});

	return result;
};

export const extractQuestions = (
	data: Array<any> | null,
	{
		isMaestro,
		maestroId,
		isTranche,
		isStructureLevel,
		isPointAssumptionQuestion,
	}: {
		isMaestro?: boolean;
		maestroId?: string;
		isTranche?: boolean;
		isStructureLevel?: boolean;
		isPointAssumptionQuestion?: boolean;
	}
) => {
	if (!data) return [];
	const res: Array<TSimulationQuestions> = data.map(
		(value: any) => {
			return {
				question: value.question,
				questionId: value.question_id,
				answers:
					value.answer_type_info.answer_type ===
					"option_selection"
						? value.answer_type_info.options?.map(
								(option: any) => ({
									id: isMaestro
										? option.maestro_id
										: isStructureLevel
											? option.option_id
											: option.run_combination_id,
									text: isMaestro
										? option.maestro_name
										: option.display_name,
								})
							)
						: [],
				answerType: value.answer_type_info.answer_type,
				defaultAnswer: value.answer,
				validations: value.answer_type_info.constraints,
				isGenAIEnabled: value.is_genai_compatible,
				answer: value.answer,
				pythonValue: value.python_transformation_code,
				naturalTextValue: value.transform_description,
				categoryId: value.category_id,
				maestroId,
				trancheId: isTranche ? value.tranche_id : null,
				assumptionName: isPointAssumptionQuestion
					? value.assumption_name
					: null,
			};
		}
	);
	return res;
};

export const extractMaestroData = (
	data: Array<{
		maestro_id: string;
		questions: Array<any>;
		lithes: Array<any>;
	}>
) => {
	const res = data.map((value: any) => {
		const questions = extractQuestions(value.questions, {
			isMaestro: true,
			maestroId: value.maestro_id,
		});
		const whichModelQuestion = questions.find(
			(question) =>
				question.questionId ===
				"00000018-0000-0000-0000-000000000000"
		);
		if (whichModelQuestion) {
			questions.splice(
				questions.indexOf(whichModelQuestion),
				1
			);
			questions.unshift(whichModelQuestion);
		}
		return {
			maestroId: value.maestro_id,
			questions,
			lithes: value.lithes,
		};
	});
	return res;
};

export const extractStructureData = (data: any) => {
	const allTranches = data.tranches.map(
		(tranche: any, index: number) => {
			const questions = extractQuestions(
				tranche.questions,
				{
					isTranche: true,
				}
			);
			return {
				key: `Tranche ${index + 1}`,
				id: tranche.tranche_id,
				questions,
				type: "tranche",
			};
		}
	);
	const resid = {
		key: "residual",
		id: null,
		questions: extractQuestions(data.resid.questions, {}),
		type: "residual",
	};
	const hammerType = {
		key: "hammerType",
		id: null,
		questions: extractQuestions(
			data.structure_level.questions,
			{ isStructureLevel: true }
		),
		type: "hammerType",
	};

	return {
		allQuestions: [...allTranches, resid],
		hammerQuestion: hammerType,
	};
};
