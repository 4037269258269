import type {
	TCreateSimulationRequestObject,
	TFilterRequestObject,
	TGetTagRequestObject,
	TMaestroUpdateRequestObject,
	TPointAssumptionRequestObject,
	TSaveSimulationRequestObject,
	TSimulationAssumptionRequestObject,
	TUpdateStructureRequestObject,
} from "../types/simulation.type";
import type { AxiosRequestConfig } from "axios";

import { getEndpoint } from "../endpoints";

import {
	httpServiceInstance,
	type HttpService,
} from "./http.service";

export class SimulationService {
	private static readonly instance: SimulationService;
	private static http: HttpService;
	private static endpoint: string;
	private static base_url: string;
	constructor() {
		SimulationService.http = httpServiceInstance;
		SimulationService.endpoint = getEndpoint();
		SimulationService.base_url = `${SimulationService.endpoint}/api`;
	}

	async getTapeForSimulation(tapeId: string): Promise<any> {
		const url = `${SimulationService.base_url}/tapes/${tapeId}`;

		const { data: response } =
			await SimulationService.http.get<any>(url);
		return response;
	}

	async getPointsForSimulation(
		tapeId: string,
		filterObject: any,
		config?: AxiosRequestConfig
	): Promise<any> {
		const url = `${SimulationService.base_url}/points/${tapeId}`;

		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: filterObject,
				signal: config?.signal,
			});
		return response;
	}

	async getValuationQuestion(
		isNewRunRequested: boolean,
		tapeId?: string,
		runId?: string | undefined
	): Promise<any> {
		const url = `${SimulationService.base_url}/valuation`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: tapeId,
					run_id: runId,
					is_new_run_requested: isNewRunRequested,
				},
			});
		return response;
	}

	async updateMaestro(
		reqObj: TMaestroUpdateRequestObject
	): Promise<any> {
		const url = `${SimulationService.base_url}/valuation/maestro/${reqObj.runId}`;
		let obj: any = {
			is_new_maestro_requested:
				reqObj.isNewMaestroRequested,
		};
		if (reqObj.runMaestroId) {
			obj = {
				...obj,
				run_maestro_id: reqObj.runMaestroId,
				question_id: reqObj.questionId,
				user_input: reqObj.userInput,
				user_input_type: reqObj.userInputType,
			};
		}
		console.log(obj);
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					...obj,
				},
			});
		return response;
	}

	async saveAnswer({
		questionId,
		userInput,
		runId,
		userInputType,
		questionCategoryId,
	}: TSimulationAssumptionRequestObject): Promise<any> {
		const url = `${SimulationService.base_url}/valuation/${runId}`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					question_id: questionId,
					user_input: userInput,
					user_input_type: userInputType,
					question_category_id: questionCategoryId,
				},
			});
		return response;
	}

	async createNewSimulation({
		runId,
		tapeId,
	}: TCreateSimulationRequestObject): Promise<any> {
		const url = `${SimulationService.base_url}/valuation/confirm/${runId}`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: tapeId,
				},
			});
		return response;
	}

	async saveSimulation({
		runId,
		tapeId,
		runName,
	}: TSaveSimulationRequestObject): Promise<any> {
		const url = `${SimulationService.base_url}/valuation/save/${runId}`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: tapeId,
					run_name: runName,
				},
			});
		return response;
	}

	async getFilterParameters(
		req: TFilterRequestObject
	): Promise<any> {
		const url = `${SimulationService.base_url}/points/filters`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: req.tapeId,
					run_id: req.runId,
				},
			});
		return response;
	}

	async saveTag(req: TGetTagRequestObject): Promise<any> {
		const url = `${SimulationService.base_url}/points/save`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: req.tapeId,
					point_id: req.pointId,
					tags: req.tags,
				},
			});
		return response;
	}

	async getTags(tapeId: string): Promise<any> {
		const url = `${SimulationService.base_url}/points/tag/${tapeId}`;
		const { data: response } =
			await SimulationService.http.get<any>(url);
		return response;
	}

	async getStructureForTape(tapeId: string): Promise<any> {
		const url = `${SimulationService.base_url}/structure/${tapeId}`;
		const { data: response } =
			await SimulationService.http.get<any>(url);
		return response;
	}

	async updateStructureForTape(
		data: TUpdateStructureRequestObject
	): Promise<any> {
		const url = `${SimulationService.base_url}/structure/${data.tapeId}`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					is_new_tranche_requested:
						data.isNewTrancheRequested,
					tranche_id: data.trancheId,
					question_id: data.questionId,
					user_input: data.userInput,
					user_input_type: data.userInputType,
					question_category_id: data.questionCategoryId,
				},
			});
		return response;
	}

	async deleteStructureForTape(
		tapeId: string,
		trancheId: string
	): Promise<any> {
		const url = `${SimulationService.base_url}/structure/${tapeId}`;
		const { data: response } =
			await SimulationService.http.delete<any>(url, {
				data: {
					tranche_id: trancheId,
				},
			});
		return response;
	}

	async activateHammerChanges(
		tapeId: string
	): Promise<any> {
		const url = `${SimulationService.base_url}/structure/confirm/${tapeId}`;
		const { data: response } =
			await SimulationService.http.post<any>(url);
		return response;
	}

	async deleteMaestroForTape(
		runId: string,
		maestroId: string
	): Promise<any> {
		const url = `${SimulationService.base_url}/valuation/maestro/${runId}`;
		const { data: response } =
			await SimulationService.http.delete<any>(url, {
				data: {
					run_maestro_id: maestroId,
				},
			});
		return response;
	}

	async getHammerDataForPoint(
		tapeId: string,
		pointId: string
	): Promise<any> {
		const url = `${SimulationService.base_url}/points/point-meta`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: tapeId,
					point_id: pointId,
				},
			});
		return response;
	}

	async updatePointAssumption(
		req: TPointAssumptionRequestObject
	): Promise<any> {
		const url = `${SimulationService.base_url}/points/assumption/${req.pointId}`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: req.tapeId,
					question_id: req.questionId,
					user_input: req.userInput,
					user_input_type: req.userInputType,
				},
			});
		return response;
	}
}
