import { createAsyncThunk } from "@reduxjs/toolkit";

import type {
	TFilterRequestObject,
	TSimulationAssumptionRequestObject,
	TSimulationPointsRequestObject,
	TSimulationQuestionRequestObject,
	TUpdateStructureRequestObject,
} from "../../types/simulation.type";

import { services } from "../../services";

export const getTapeForSimulation = createAsyncThunk(
	"simulation/getTapeInfo",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.simulationService.getTapeForSimulation(
					tapeId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const getPointsForSimulationThunk = createAsyncThunk(
	"simulation/getPointsForSimulationThunk",
	async (
		req: TSimulationPointsRequestObject,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.simulationService.getPointsForSimulation(
					req.tapeId,
					req.filterObject
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const getQuestionForSimulationThunk =
	createAsyncThunk(
		"simulation/getQuestionForSimulationThunk",
		async (
			req: TSimulationQuestionRequestObject,
			{ dispatch, rejectWithValue }
		) => {
			try {
				const data =
					await services.simulationService.getValuationQuestion(
						req.isNewRunRequested,
						req.tapeId,
						req.runId
					);
				return data;
			} catch (error: any) {
				console.error("redux error", error);
				return rejectWithValue({
					...error.response.data,
					msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
				});
			}
		}
	);

export const getQuestionsForModel = createAsyncThunk(
	"simulation/getQuestionsForModel",
	async (
		req: TSimulationAssumptionRequestObject,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.simulationService.saveAnswer(req);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const getFilterParameterThunk = createAsyncThunk(
	"simulation/getFilterParameterThunk",
	async (
		req: TFilterRequestObject,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.simulationService.getFilterParameters(
					req
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);
export const getPointTags = createAsyncThunk(
	"simulation/getPointTags",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.simulationService.getTags(tapeId);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const getStructureForTapeThunk = createAsyncThunk(
	"simulation/getStructureForTapeThunk",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.simulationService.getStructureForTape(
					tapeId
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);
export const updateStructureForTapeThunk = createAsyncThunk(
	"simulation/updateStructureForTapeThunk",
	async (
		reqData: TUpdateStructureRequestObject,
		{ dispatch, rejectWithValue }
	) => {
		try {
			const data =
				await services.simulationService.updateStructureForTape(
					reqData
				);
			return data;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);
