import { isEqual, set } from "lodash";
import {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getStatistics } from "../redux/sanitization/sanitization.thunk";
import { TapeSimulationSliceActions } from "../redux/simulation/simulation.slice";
import {
	getPointTags,
	getQuestionForSimulationThunk,
	getStructureForTapeThunk,
	getTapeForSimulation,
} from "../redux/simulation/simulation.thunk";
import {
	useAppDispatch,
	useAppSelector,
} from "../redux/store";
import { services } from "../services";
import { AnalysisStageEnum } from "../types/analysis.type";
import { SimulationEnum } from "../types/simulation.type";

export const SimulationContext = createContext<any>(null);

export const SimulationContextProvider = ({
	children,
}: any) => {
	const dispatch = useAppDispatch();
	const params = useParams<any>();

	const { tapeId } = params;
	const { activeTenant } = useAppSelector(
		(state) => state.tenant
	);

	const {
		simulationMeta,
		savedSimulations,
		simulationAxisData,
		structure,
	} = useAppSelector((state) => state.simulation);

	const [isSimulationSaved, setIsSimulationSaved] =
		useState<boolean>(false);

	const [
		editAssumptionDrawerOpen,
		setEditAssumptionDrawerOpen,
	] = useState(false);

	const [showPointDialog, setShowPointDialog] =
		useState(false);

	const [filterDrawerOpen, setFilterDrawerOpen] =
		useState(false);

	const [structureDrawerOpen, setStructureDrawerOpen] =
		useState(false);

	const [
		saveSimulationModalOpen,
		setSaveSimulationModalOpen,
	] = useState(false);

	const simulationLoadingRef = useRef(false);
	const [
		isUntitledRunAvailable,
		setIsUntitledRunAvailable,
	] = useState<boolean>(false);

	const [persistFilterObject, setPersistFilterObject] =
		useState<any>({});

	const [selectedTags, setSelectedTags] = useState<
		Array<string>
	>([]);

	const [isFilterApplied, setIsFilterApplied] =
		useState(false);

	const [filterCount, setFilterCount] = useState(0);

	const [savePointModalOpen, setSavePointModalOpen] =
		useState(false);

	const [coordinates, setCoordinates] = useState<{
		x: number;
		y: number;
	} | null>(null);

	const [selectedRunId, setSelectedRunId] = useState("");

	const [errorOccurred, setErrorOccurred] = useState<any>({
		isError: false,
		tapeId: null,
		status: null,
	});

	const [is3d, setIs3d] = useState(true);

	const [axisLabel, setAxisLabel] = useState<{
		x: string | null;
		y: string | null;
		z: string | null;
	}>({
		x: null,
		y: null,
		z: "price",
	});

	const controller = useRef<any>({});
	const timeout = useRef<NodeJS.Timeout | null>(null);
	const continuePolling = useRef(true);

	const [simulationCoachMarks, setSimulationCoachMarks] =
		useState({
			assumptions: false,
			filter: false,
			select_model: false,
		});

	useEffect(() => {
		const showAssumptionCoachMark = localStorage.getItem(
			"showAssumptionCoachMark"
		);
		const showFilterCoachMark = localStorage.getItem(
			"showFilterCoachMark"
		);
		const showSelectModelCoachMark = localStorage.getItem(
			"showSelectModelCoachMark"
		);
		const temp = { ...simulationCoachMarks };
		if (
			!showAssumptionCoachMark ||
			showAssumptionCoachMark === "true"
		) {
			temp.assumptions = true;
		}
		if (
			!showFilterCoachMark ||
			showFilterCoachMark === "true"
		) {
			temp.filter = true;
		}
		if (
			!showSelectModelCoachMark ||
			showSelectModelCoachMark === "true"
		) {
			temp.select_model = true;
		}
		if (!isEqual(temp, simulationCoachMarks))
			setSimulationCoachMarks(temp);
	}, [simulationCoachMarks]);

	const closeCoachMark = useCallback(
		(key: "assumptions" | "filter" | "select_model") => {
			const temp = { ...simulationCoachMarks };
			temp[key] = false;
			setSimulationCoachMarks(temp);
			switch (key) {
				case "assumptions": {
					localStorage.setItem(
						"showAssumptionCoachMark",
						"false"
					);
					break;
				}
				case "filter": {
					localStorage.setItem(
						"showFilterCoachMark",
						"false"
					);
					break;
				}
				case "select_model": {
					localStorage.setItem(
						"showSelectModelCoachMark",
						"false"
					);
					break;
				}
				default:
					break;
			}
		},
		[simulationCoachMarks]
	);

	const openStructureDrawer = useCallback(() => {
		if (tapeId) {
			setStructureDrawerOpen(true);
			if (structure.data === null) {
				void dispatch(getStructureForTapeThunk(tapeId));
			}
		}
	}, [tapeId, structure.data, dispatch]);

	const handlePolling = useCallback(
		async (tapeId: string, filterObject: any) => {
			try {
				console.log(
					"Polling started",
					tapeId,
					filterObject
				);
				dispatch(
					TapeSimulationSliceActions.enableLoadingOfPoints(
						true
					)
				);
				const res =
					await services.simulationService.getPointsForSimulation(
						tapeId,
						filterObject,
						{
							signal:
								controller.current[filterObject.run_id]
									.signal,
						}
					);
				if (res.points) {
					dispatch(
						TapeSimulationSliceActions.updateNewPoints(res)
					);
				}
				if (
					res.status !== AnalysisStageEnum.PRICING_COMPLETED
				) {
					timeout.current = setTimeout(() => {
						continuePolling.current = true;
						void handlePolling(tapeId, filterObject);
					}, 15000);
				} else {
					console.log("Polling stopped", res);
					continuePolling.current = false;
				}
			} catch (error) {
				console.error("handling polling", error);
				continuePolling.current = false;
				dispatch(
					TapeSimulationSliceActions.enableLoadingOfPoints(
						false
					)
				);
				setErrorOccurred({
					isError: true,
					tapeId,
					status: AnalysisStageEnum.PRICING_FAILED,
				});
			}
		},
		[dispatch, controller]
	);

	const [appliedTags, setAppliedTags] = useState<
		Array<string>
	>([]);

	const applyTags = useCallback((tags: Array<string>) => {
		setAppliedTags(tags);
	}, []);

	const handleFilterPoints = useCallback(
		async (
			tapeId: string,
			filterObject: any,
			abort?: any
		) => {
			try {
				if (filterObject.tags) {
					applyTags(filterObject.tags);
				} else {
					setAppliedTags([]);
				}

				dispatch(
					TapeSimulationSliceActions.enableLoadingOfPoints(
						true
					)
				);
				const res =
					await services.simulationService.getPointsForSimulation(
						tapeId,
						filterObject,
						{
							signal: abort?.signal,
						}
					);
				dispatch(
					TapeSimulationSliceActions.updateNewPoints(res)
				);
				setFilterDrawerOpen(false);
				setIsFilterApplied(true);
				let count = 0;
				for (const key in filterObject) {
					if (filterObject[key]) {
						for (const filterKey in filterObject[key]) {
							if (filterObject[key][filterKey]) {
								count++;
							}
						}
					}
				}
				console.log("Filter count", count, filterObject);
				setFilterCount(count);
			} catch (error) {
				console.error("handling polling", error);
				dispatch(
					TapeSimulationSliceActions.enableLoadingOfPoints(
						false
					)
				);
				continuePolling.current = false;
				setErrorOccurred({
					isError: true,
					tapeId,
					status: AnalysisStageEnum.PRICING_FAILED,
				});
			}
		},
		[applyTags, dispatch]
	);

	const handleRunSelection = useCallback(
		(runId: string) => {
			if (tapeId) {
				controller.current[runId] = new AbortController();
				if (selectedRunId && selectedRunId !== runId) {
					if (controller.current[selectedRunId]) {
						controller.current[selectedRunId].abort();
						clearTimeout(timeout.current as NodeJS.Timeout);
					}
				}
				dispatch(
					TapeSimulationSliceActions.resetQuestions()
				);
				setSelectedRunId(runId);
				void handlePolling(tapeId, { run_id: runId });
				setPersistFilterObject({});
				setIsFilterApplied(false);
				setAppliedTags([]);
				setFilterCount(0);
			}
		},
		[tapeId, selectedRunId, dispatch, handlePolling]
	);

	const simulation = useMemo(() => {
		const data: {
			x: Array<number>;
			y: Array<number>;
			z: Array<number> | undefined;
		} = {
			x: axisLabel.x ? simulationAxisData[axisLabel.x] : [],
			y: axisLabel.y ? simulationAxisData[axisLabel.y] : [],
			z: axisLabel.z
				? simulationAxisData[axisLabel.z]
				: undefined,
		};
		return data;
	}, [
		axisLabel.x,
		axisLabel.y,
		axisLabel.z,
		simulationAxisData,
	]);

	const activeSimulation = useMemo(() => {
		if (
			savedSimulations.length === 0 ||
			selectedRunId.length === 0
		)
			return null;
		else
			return savedSimulations.find(
				(simulation) => simulation.id === selectedRunId
			);
	}, [savedSimulations, selectedRunId]);

	const getQuestion = useCallback(
		async (action: SimulationEnum) => {
			if (tapeId) {
				let isNewRunRequested = false;
				switch (action) {
					case SimulationEnum.CREATE:
						isNewRunRequested = true;
						break;
					case SimulationEnum.EDIT:
					case SimulationEnum.VIEW:
						isNewRunRequested = false;
						break;
					default:
						break;
				}
				void dispatch(
					getQuestionForSimulationThunk({
						tapeId: isNewRunRequested ? tapeId : undefined,
						isNewRunRequested,
						runId: isNewRunRequested ? "" : selectedRunId,
					})
				);
			}
		},
		[tapeId, selectedRunId, dispatch]
	);

	const [simulationAction, setSimulationAction] =
		useState<SimulationEnum | null>(null);

	const handleEditAssumptionDrawer = useCallback(
		(action: SimulationEnum) => {
			void getQuestion(action);
			setSimulationAction(action);
			setEditAssumptionDrawerOpen(true);
		},
		[getQuestion]
	);

	const resetEverything = useCallback(() => {
		setSimulationAction(null);
		setEditAssumptionDrawerOpen(false);
		dispatch(TapeSimulationSliceActions.resetEverything());
		setSelectedRunId("");
		setErrorOccurred({
			isError: false,
			tapeId: null,
			status: null,
		});
		setIs3d(true);
		setAxisLabel({ x: null, y: null, z: "price" });
		controller.current = {};
		clearTimeout(timeout.current as NodeJS.Timeout);
		continuePolling.current = true;
		setPersistFilterObject({});
		setSelectedTags([]);
		setIsFilterApplied(false);
		setFilterCount(0);
		setCoordinates(null);
		setSaveSimulationModalOpen(false);
		setSavePointModalOpen(false);
		setShowPointDialog(false);
		setFilterDrawerOpen(false);
		setStructureDrawerOpen(false);
		setSimulationCoachMarks({
			assumptions: false,
			filter: false,
			select_model: false,
		});
	}, [dispatch]);

	useEffect(() => {
		if (tapeId && activeTenant) {
			void dispatch(getTapeForSimulation(tapeId));
			void dispatch(getPointTags(tapeId));
			void dispatch(getStatistics(tapeId));
		}
		return () => {
			if (controller.current[selectedRunId]) {
				controller.current[selectedRunId].abort();
			}
			dispatch(
				TapeSimulationSliceActions.resetEverything()
			);
		};
	}, [activeTenant, dispatch, selectedRunId, tapeId]);

	const updateSavedSimulation = useCallback(
		async (tapeId: string) => {
			try {
				if (simulationAction === SimulationEnum.CREATE) {
					void dispatch(getTapeForSimulation(tapeId));
				} else {
					const data =
						await services.simulationService.getTapeForSimulation(
							tapeId
						);
					dispatch(
						TapeSimulationSliceActions.updateSavedSimulations(
							data
						)
					);
				}
			} catch (error) {
				console.error(
					"Error updating saved simulation",
					error
				);
				toast.error("Error updating saved simulation");
			}
		},
		[dispatch, simulationAction]
	);

	const createSimulation = useCallback(async () => {
		try {
			if (!tapeId || !simulationMeta?.runId) return;
			dispatch(
				TapeSimulationSliceActions.setPageLoading(true)
			);
			const data =
				await services.simulationService.createNewSimulation(
					{
						runId: simulationMeta.runId,
						tapeId,
					}
				);
			if (data.is_success) {
				await updateSavedSimulation(tapeId);
				handleRunSelection(simulationMeta.runId);
				setEditAssumptionDrawerOpen(false);
				simulationLoadingRef.current = false;
				dispatch(
					TapeSimulationSliceActions.setPageLoading(false)
				);
			}
		} catch (error) {
			console.error("Error creating simulation", error);
			simulationLoadingRef.current = false;
		}
	}, [
		dispatch,
		handleRunSelection,
		simulationMeta?.runId,
		tapeId,
		updateSavedSimulation,
	]);

	const localCloseTape = useCallback(() => {
		setSimulationAction(null);
		setEditAssumptionDrawerOpen(false);
		dispatch(
			TapeSimulationSliceActions.setValuationQuestions({
				questions: [],
				loading: false,
				error: null,
			})
		);
	}, [
		dispatch,
		setEditAssumptionDrawerOpen,
		setSimulationAction,
	]);

	const removeSelectedPoint = useCallback(() => {
		dispatch(TapeSimulationSliceActions.selectPoint(null));
	}, [dispatch]);

	return (
		<SimulationContext.Provider
			value={{
				tapeId,
				isSimulationSaved,
				setIsSimulationSaved,
				editAssumptionDrawerOpen,
				setEditAssumptionDrawerOpen,
				handleEditAssumptionDrawer,
				saveSimulationModalOpen,
				setSaveSimulationModalOpen,
				savePointModalOpen,
				setSavePointModalOpen,
				handleRunSelection,
				selectedRunId,
				filterDrawerOpen,
				setFilterDrawerOpen,
				createSimulation,
				showPointDialog,
				setShowPointDialog,
				coordinates,
				setCoordinates,
				simulationAction,
				setSimulationAction,
				localCloseTape,
				isUntitledRunAvailable,
				activeSimulation,
				handleFilterPoints,
				persistFilterObject,
				setPersistFilterObject,
				isFilterApplied,
				simulationLoadingRef,
				continuePolling,
				axisLabel,
				setAxisLabel,
				simulation,
				is3d,
				setIs3d,
				filterCount,
				setFilterCount,
				simulationCoachMarks,
				closeCoachMark,
				removeSelectedPoint,
				structureDrawerOpen,
				setStructureDrawerOpen,
				openStructureDrawer,
				selectedTags,
				setSelectedTags,
				appliedTags,
			}}
		>
			{children}
		</SimulationContext.Provider>
	);
};
