export type TSimulationSlice = {
	tapeSimulationInfo: any;
	pageShouldLoad: boolean;
	savedSimulations: Array<any>;
	defaultRunId: string;
	latestRunId: string;
	untitledRunId: string;
	rawPointsData: {
		loading: boolean;
		data: Array<any>;
		status?: string | null;
		error: any;
	} | null;
	simulationAxisData: Record<string, Array<any>>;
	selectedPoint: Record<string, any> | null;
	assumptionQuestionsForSelectedPoint: Array<TSimulationQuestions> | null;
	activeLabels: {
		x: string | null;
		y: string | null;
		z: string | null;
	};
	valuationQuestions: {
		assumptionsQuestions: Array<TSimulationQuestions> | null;
		filtersQuestions: Array<TSimulationQuestions> | null;
		maestroQuestions: Array<{
			questions: Array<TSimulationQuestions>;
			maestroId: string;
			lithes: Array<any>;
		}> | null;
		whichModel: Array<TSimulationQuestions> | null;
		loading: boolean;
		error: any;
	};
	simulationMeta?: {
		runId: string;
		runName: string;
		loading: boolean;
		error: any;
	};
	filterParameters: Array<Record<string, any>> | null;
	tagList: {
		allAvailableTags: Array<string>;
		pointTags: Array<Record<string, any>>;
	} | null;
	structure: any;
};

export type TSimulationPointsRequestObject = {
	tapeId: string;
	filterObject: any;
};

export type TSimulationQuestionRequestObject = {
	tapeId?: string;
	runId?: string | undefined;
	isNewRunRequested: boolean;
};

export type TSimulationQuestions = {
	question: string;
	questionId: string;
	answers: Array<{
		id: string;
		text: string;
	}>;
	categoryId?: string;
	maestroId?: string;
	answerType:
		| "float_list"
		| "int_list"
		| "tape_filters"
		| "tape_overrides"
		| "int"
		| "option_selection";
	answer: string | null;
	defaultAnswer: string | null;
	validations?: any;
	isGenAIEnabled: boolean;
	pythonValue: string;
	naturalTextValue: string;
	trancheId?: string;
	assumptionName?: string;
};

export type TSimulationAssumptionRequestObject = {
	questionId: string;
	questionCategoryId: string;
	userInput: string;
	runId: string;
	userInputType:
		| "natural_language"
		| "python_code"
		| "option_selection";
};

export type TPointAssumptionRequestObject = {
	pointId: string;
	questionId: string;
	userInput: string;
	userInputType:
		| "natural_language"
		| "python_code"
		| "option_selection";
	tapeId: string;
};

export type TMaestroUpdateRequestObject = {
	runId: string;
	isNewMaestroRequested: boolean;
	runMaestroId?: string;
	questionId?: string;
	userInput?: string;
	userInputType?:
		| "natural_language"
		| "python_code"
		| "option_selection";
};

export type TCreateSimulationRequestObject = {
	runId: string;
	tapeId: string;
};

export type TSaveSimulationRequestObject = {
	runId: string;
	tapeId: string;
	runName: string;
};

export type TFilterRequestObject = {
	tapeId?: string;
	runId?: string;
};

export type TGetTagRequestObject = {
	tapeId: string;
	pointId: string;
	tags: Array<string>;
};

export enum SimulationEnum {
	"CREATE" = "createSimulation",
	"VIEW" = "viewSimulation",
	"EDIT" = "editSimulation",
}

export enum HammerOutputStatusEnum {
	NA = "NOT AVAILABLE",
	YET_TO_START = "YET_TO_START",
	PROCESSING = "PROCESSING",
	COMPLETED = "COMPLETED",
}

export type TPointMetaInfo = {
	assumption: {
		questions: Array<{
			question_id: string;
			answer: string;
			assumption_name: string;
			is_valid_answer: boolean;
		}>;
	};
	structure: {
		data?: Array<any>;
		status: HammerOutputStatusEnum;
	};
};

export type TUpdateStructureRequestObject = {
	tapeId: string;
	isNewTrancheRequested: boolean;
	questionCategoryId?: string;
	isResid?: boolean;
	trancheId?: string;
	questionId?: string;
	userInput?: string;
	userInputType?:
		| "natural_language"
		| "python_code"
		| "option_selection";
};
