import { createSlice } from "@reduxjs/toolkit";

import type { TSimulationSlice } from "../../types/simulation.type";

import {
	extractMaestroData,
	extractQuestions,
	extractSimulationAxisData,
	extractSimulations,
	extractStructureData,
} from "../../utils/simulationUtils";

import {
	getFilterParameterThunk,
	getPointsForSimulationThunk,
	getPointTags,
	getQuestionForSimulationThunk,
	getStructureForTapeThunk,
	getTapeForSimulation,
	updateStructureForTapeThunk,
} from "./simulation.thunk";

const initialState: TSimulationSlice = {
	tapeSimulationInfo: null,
	pageShouldLoad: false,
	savedSimulations: [],
	defaultRunId: "",
	latestRunId: "",
	untitledRunId: "",
	rawPointsData: null,
	simulationAxisData: {},
	selectedPoint: null,
	assumptionQuestionsForSelectedPoint: null,
	activeLabels: {
		x: null,
		y: null,
		z: null,
	},
	valuationQuestions: {
		assumptionsQuestions: null,
		filtersQuestions: null,
		maestroQuestions: null,
		whichModel: null,
		loading: false,
		error: null,
	},
	filterParameters: null,
	tagList: null,
	structure: {
		loading: false,
		data: null,
		error: null,
	},
};

export const TapeSimulationSlice = createSlice({
	name: "tapes",
	initialState,
	reducers: {
		selectPoint: (state, action) => {
			state.selectedPoint = action.payload;
		},
		setValuationQuestions: (state, action) => {
			state.valuationQuestions = action.payload;
		},
		setPageLoading: (state, action) => {
			state.pageShouldLoad = action.payload;
		},
		updateNewPoints: (state, action) => {
			state.rawPointsData = {
				loading: false,
				data: action.payload.points,
				status: action.payload.status,
				error: null,
			};
			state.simulationAxisData = extractSimulationAxisData(
				action.payload.points
			);
			// state.selectedPoint = action.payload.points[0];
		},
		updateSavedSimulations: (state, action) => {
			if (action.payload.allowed) {
				const tapeInfo = action.payload.tape;
				state.tapeSimulationInfo = tapeInfo;
				state.savedSimulations = extractSimulations(
					tapeInfo.valuation_runs.saved_runs,
					tapeInfo.valuation_runs.default_run_id as string
				);
				state.defaultRunId =
					tapeInfo.valuation_runs.default_run_id;
				state.latestRunId =
					tapeInfo.valuation_runs.latest_run_id;
				state.untitledRunId =
					tapeInfo.valuation_runs.untitled_run_id;
			} else state.tapeSimulationInfo = null;
		},
		enableLoadingOfPoints: (state, action) => {
			state.rawPointsData = {
				loading: action.payload,
				data: [],
				error: null,
			};
		},
		resetEverything: (state) => {
			state = initialState;
		},
		resetQuestions: (state) => {
			state.valuationQuestions = {
				maestroQuestions: null,
				filtersQuestions: null,
				assumptionsQuestions: null,
				whichModel: null,
				loading: false,
				error: null,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getTapeForSimulation.pending, (state) => {
				console.log("pending");
				state.pageShouldLoad = true;
			})
			.addCase(
				getTapeForSimulation.fulfilled,
				(state, action) => {
					if (action.payload.allowed) {
						const tapeInfo = action.payload.tape;
						state.tapeSimulationInfo = tapeInfo;
						state.savedSimulations = extractSimulations(
							tapeInfo.valuation_runs.saved_runs,
							tapeInfo.valuation_runs
								.default_run_id as string
						);
						state.defaultRunId =
							tapeInfo.valuation_runs.default_run_id;
						state.latestRunId =
							tapeInfo.valuation_runs.latest_run_id;
						state.untitledRunId =
							tapeInfo.valuation_runs.untitled_run_id;
					} else state.tapeSimulationInfo = null;
					state.pageShouldLoad = false;
				}
			)
			.addCase(getTapeForSimulation.rejected, (state) => {
				console.log("rejected");
				state.pageShouldLoad = false;
			});
		builder
			.addCase(
				getPointsForSimulationThunk.pending,
				(state) => {
					state.rawPointsData = {
						loading: true,
						data: [],
						error: null,
					};
				}
			)
			.addCase(
				getPointsForSimulationThunk.fulfilled,
				(state, action) => {
					state.rawPointsData = {
						loading: false,
						data: action.payload.points,
						status: action.payload.status,
						error: null,
					};
					state.simulationAxisData =
						extractSimulationAxisData(
							action.payload.points
						);
					// state.selectedPoint = action.payload.points[0];
				}
			)
			.addCase(
				getPointsForSimulationThunk.rejected,
				(state, action) => {
					state.rawPointsData = {
						loading: false,
						data: [],
						error: action.payload,
					};
				}
			);
		builder
			.addCase(
				getQuestionForSimulationThunk.pending,
				(state) => {
					state.valuationQuestions.loading = true;
					state.valuationQuestions.error = null;
				}
			)
			.addCase(
				getQuestionForSimulationThunk.fulfilled,
				(state, action) => {
					const data = action.payload;
					if (data.questions && data.model_id) {
						data.questions.which_model.default_value =
							data.model_id;
					}

					const maestroData = extractMaestroData(
						data.maestros
					);

					state.valuationQuestions = {
						maestroQuestions: maestroData,
						filtersQuestions: extractQuestions(
							data.questions.tape_filters,
							{}
						),
						assumptionsQuestions: extractQuestions(
							data.questions.assumptions,
							{}
						),
						whichModel: extractQuestions(
							data.questions.which_model,
							{}
						),
						loading: false,
						error: null,
					};
					state.simulationMeta = {
						runId: data.run_id,
						runName: data.run_name,
						loading: false,
						error: null,
					};
				}
			)
			.addCase(
				getQuestionForSimulationThunk.rejected,
				(state, action) => {
					state.valuationQuestions = {
						maestroQuestions: null,
						filtersQuestions: null,
						assumptionsQuestions: null,
						whichModel: null,
						loading: false,
						error: action.payload,
					};
				}
			);
		builder
			.addCase(getFilterParameterThunk.pending, (state) => {
				state.filterParameters = null;
			})
			.addCase(
				getFilterParameterThunk.fulfilled,
				(state, action) => {
					state.filterParameters = action.payload;
				}
			)
			.addCase(
				getFilterParameterThunk.rejected,
				(state, action) => {}
			);
		builder
			.addCase(getPointTags.pending, (state) => {
				state.tagList = null;
			})
			.addCase(getPointTags.fulfilled, (state, action) => {
				const list = action.payload;
				state.tagList = {
					allAvailableTags: list.all_available_tags,
					pointTags: Object.values(list.tags),
				};
			})
			.addCase(
				getPointTags.rejected,
				(state, action) => {}
			);

		builder
			.addCase(
				getStructureForTapeThunk.pending,
				(state) => {
					state.structure = {
						...state.structure,
						loading: true,
						error: null,
					};
				}
			)
			.addCase(
				getStructureForTapeThunk.fulfilled,
				(state, action) => {
					const structureData = action.payload;
					state.structure = {
						loading: false,
						data: extractStructureData(structureData),
						error: null,
					};
				}
			)
			.addCase(
				getStructureForTapeThunk.rejected,
				(state, action) => {
					state.structure = {
						loading: false,
						data: null,
						error: action.payload,
					};
				}
			);
		builder
			.addCase(
				updateStructureForTapeThunk.pending,
				(state) => {
					state.structure.loading = true;
				}
			)
			.addCase(
				updateStructureForTapeThunk.fulfilled,
				(state, action) => {
					state.structure = {
						loading: false,
						data: extractStructureData(action.payload),
						error: null,
					};
				}
			)
			.addCase(
				updateStructureForTapeThunk.rejected,
				(state, action) => {
					state.structure = {
						loading: false,
						data: null,
						error: action.payload,
					};
				}
			);
	},
});

export const TapeSimulationSliceActions =
	TapeSimulationSlice.actions;
export const TapeSimulationReducer =
	TapeSimulationSlice.reducer;
