import React, {
	createContext,
	useCallback,
	useEffect,
	useRef,
	useState,
	type ReactElement,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import type {
	AssetClassEnum,
	TTape,
} from "../types/tapes.type";

import { AnalysisActions } from "../redux/analysis/analysis.slice";
import { startAnalysisThunk } from "../redux/analysis/analysis.thunk";
import { uploadTapeThunk } from "../redux/sanitization/sanitization.thunk";
import {
	useAppDispatch,
	useAppSelector,
} from "../redux/store";
import { TapeSliceActions } from "../redux/tapes/tapes.slice";
import { UserAuthActions } from "../redux/user/user.slice";
import { services } from "../services";
import { AnalysisStageEnum } from "../types/analysis.type";
import { generatedPlotValues } from "../utils/analysisUtils";

type TTapeExist = {
	isCheckDone: boolean;
	exist: boolean;
	tape: TTape | null;
};

// Create the context
export const AnalysisContext = createContext<any>(null);

// Create the provider component
export const AnalysisProvider: React.FC<{
	children: ReactElement;
}> = ({ children }) => {
	const [point, setPoint] = useState<any>(null);
	const [downloadLink, setDownloadLink] =
		useState<string>("");
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { activeTapeId, currentAnalysis } = useAppSelector(
		(state) => state.analysis
	);

	const [isAvailable, setIsAvailable] = useState({
		keyOutputs: false,
		assumptions: false,
		loanTapeStats: false,
		finalOutput: false,
	});
	const [graphData, setGraphData] = useState<any>(null);
	const [graphLabels, setGraphLabels] = useState<any>(null);

	const selectedIndex = useRef<any>(null);

	const [pollingResponse, setPollingResponse] =
		useState<any>(null);

	const [refreshAvailable, setRefreshAvailable] =
		useState(false);

	const { allTapes } = useAppSelector(
		(state) => state.tapes
	);
	const { activeTenant } = useAppSelector(
		(state) => state.tenant
	);

	const { user } = useAppSelector((state) => state.user);

	const timeout = useRef<NodeJS.Timeout | null>(null);
	const isTapeSwitched = useRef(false);

	const [errorOccurred, setErrorOccurred] = useState<any>({
		isError: false,
		tapeId: null,
		status: null,
	});

	const controller = useRef<any>({});

	const [tapeExist, setTapeExist] = useState<TTapeExist>({
		isCheckDone: false,
		exist: false,
		tape: null,
	});

	const [activeTapeForEdit, setActiveTapeForEdit] =
		useState<TTape | null>(null);

	const getTape = useCallback(
		async (tapeId: string) => {
			const tapeStatus =
				await services.analysisServiceInstance.searchTapeById(
					tapeId
				);
			if (tapeStatus.allowed && tapeStatus.tape) {
				setTapeExist({
					isCheckDone: true,
					exist: true,
					tape: tapeStatus.tape,
				});
				dispatch(TapeSliceActions.addTape(tapeStatus.tape));
			} else {
				setTapeExist({
					isCheckDone: true,
					exist: false,
					tape: null,
				});
			}
		},
		[dispatch]
	);

	const resetEverything = useCallback(
		(stopLoading?: boolean) => {
			console.log("Resetting everything");
			clearTimeout(timeout.current as NodeJS.Timeout);
			dispatch(
				AnalysisActions.setCurrentAnalysisData(null)
			);
			dispatch(AnalysisActions.setOriginalTapeData(null));
			setPoint(null);
			setDownloadLink("");
			dispatch(AnalysisActions.setSelectedDataPoint(null));
			setRefreshAvailable(false);
			setPollingResponse(null);
			selectedIndex.current = null;
			setIsAvailable({
				keyOutputs: false,
				assumptions: false,
				loanTapeStats: false,
				finalOutput: false,
			});
			setGraphData(null);
			setGraphLabels(null);
			setErrorOccurred({
				isError: false,
				tapeId: null,
				status: null,
			});
			dispatch(
				AnalysisActions.setCurrentAnalysisLoading(
					!stopLoading
				)
			);

			isTapeSwitched.current = true;
		},
		[dispatch, timeout]
	);

	useEffect(() => {
		if (activeTenant && user) {
			dispatch(
				UserAuthActions.updateGlobalAdmin(activeTenant)
			);
		}
	}, [activeTenant, user, dispatch]);

	const uploadTape = useCallback(
		(
			file: File,
			tapeName: string,
			assetClass: AssetClassEnum
		) => {
			void dispatch(
				uploadTapeThunk({
					file,
					name: tapeName,
					assetClass,
				})
			);
		},
		[dispatch]
	);

	const [tapeWithError, setTapeWithError] = useState<
		string | null
	>(null);

	//  states and functions exposed to the children components
	const value = {
		point,
		setPoint,
		downloadLink,
		setDownloadLink,
		uploadTape,
		isAvailable,
		graphData,
		graphLabels,
		refreshAvailable,
		selectedIndex,
		resetEverything,
		tapeWithError,
		errorOccurred,
		setErrorOccurred,
		getTape,
		tapeExist,
		activeTapeForEdit,
		setActiveTapeForEdit,
	};
	return (
		<AnalysisContext.Provider value={value}>
			{children}
		</AnalysisContext.Provider>
	);
};
